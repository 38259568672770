
import React from 'react';
import { Box, Button, Container, Heading, Image, Text } from "theme-ui";
import SectionHeading from "../components/section-heading";
import LogoCollision from "../assets/images/Collision/Collision.svg"
import { Link } from 'gatsby';

const Banner = () => {
    return (
        <Box as="section" id="about" sx={styles.section}>
            <Container>
                <Box sx={styles.grid}>
                    <Box sx={styles.content}>
                        <SectionHeading
                            sx={styles.heading}
                            slogan='Partnership'
                        />
                        <Heading sx={styles.title}>
                            Welcome to Adelphatech <br />
                            Your Partner in Rapid Prototyping!
                        </Heading>
                        <Text as="h5" sx={styles.summary}>
                            At Adelphatech, we understand the urgency of bringing your startup ideas to life. That's why we've partnered with Lescalator to provide you with cutting-edge tools and expert support to turn your vision into reality quickly and cost-effectively.
                        </Text>
                        <Link
                            className='btn buttonform'
                            to="/contact-us"
                        >
                            Tell us About your Project
                        </Link>
                    </Box>
                    <Box sx={styles.svgicon}>
                        <Image src={LogoCollision} alt="svg-icon" loading="lazy" />
                    </Box>

                </Box>
            </Container>
        </Box>
    );
};

export default Banner;

const styles = {
    section: {
        backgroundColor: "#fff",
        pt: [18, null, null, 6, null, null, 15],
        pb: [0, null, null, 8, null, null, 11],
    },
    grid: {
        display: ['flex', null, null, 'grid'],
        flexDirection: ['column-reverse', null, null, 'unset'],
        gap: '0 30px',
        alignItems: 'center',
        gridTemplateColumns: [
            '1fr',
            null,
            null,
            '1fr',
            '1fr 1fr',
            '600px 1fr',
            '760px 1fr',
        ],
    },
    content: {
        px: [null, null, null, 8, 0],
        order:[1,0]
    },
    playPause: {
        color: 'white',
        fontWeight: 700,
        position: 'absolute',
        padding: '0px',
        bottom: [40, null, null, 70, 100],
        left: '50%',
        transform: 'translateX(-50%)',
        ':focus': {
            outline: 0,
        },
        img: {
            mr: '16px',
        },
    },
    title: {
        color: '#09386b',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 15],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.53],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    summary: {
        color: 'textSecondary',
        fontSize: ["15px", null, null, 2, '15px', 4],
        lineHeight: [1.86, null, null, null, 1.86, 1.86],
        mt: [4, null, null, 5, 3, 5],
        mb: [4, null, null, 5, 3, 5],
        textAlign: ['center', null, null, 'left'],
    },
    svgicon: {
        pt: [7, null, null, 6, null, null, 13],
        width: ["100%", "100%",]
    },
    '.buttonform': {
        borderRadius: 0,
        border: ' 1px solid #09386b',
        backgroundColor: 'transparent !important',
        color: '#09386b',
        minHeight: '44px',
    },
};
